import { useCallback, useEffect, useState } from 'react';

import { useBrowserStorage } from '@aspendental/shared-utils/react';
import { useRouter } from 'next/router';

import { FACILITY_CODE_STORAGE_KEY, IUser } from '@/constants';
import { getGraphQLClient } from '@/graphql/client';
import { BffBrandType, BffFacility, FacilityBrand, NearbyFacilitiesList, NearbyFacility } from '@/types/generated';
import { FacilityFieldsFragment } from '@/graphql/__generated/sdk';
import { Maybe, TAGBrand } from '@/types';
import { getGraphHeaders } from '@/utils/getGraphHeaders';
import getLocationDetails from '@/services/getLocationDetails';
import { Location } from '@/rest/__generated/revspringApi';
import { getBffNearByFacilitiesByLocation } from '@/services/getBffNearByFacilitiesByLocation';
import { mapFacilities } from '@/utils/mapFacilitiesResults/mapFacilitiesResults';

import { getBffFacilityById } from './getBffFacilityById';

interface INearbyFacilities {
	appName: string;
	appVersion: string;
	enterpriseGraphqlUrl: string;
	brand: TAGBrand;
	coords: {
		latitude: number;
		longitude: number;
	};
	withinRadius?: number;
}

async function getFacilityDetails(
	brand: TAGBrand,
	facilityCode: string,
	appName: string,
	appVersion: string,
	enterpriseGraphqlUrl: string
) {
	const facilityBrand = brandThemeToFacilityBrand(brand);
	const facilityInfoResp = await getGraphQLClient(enterpriseGraphqlUrl).facilityByCode(
		{
			code: facilityCode,
			brand: facilityBrand as FacilityBrand,
		},
		getGraphHeaders(appName, appVersion)
	);
	const facilityInfo = facilityInfoResp.facility as FacilityFieldsFragment;
	return facilityInfo;
}
// get 200 miles nearby facilities by default for now
async function getNearbyFacilities({
	appName,
	appVersion,
	enterpriseGraphqlUrl,
	brand,
	coords,
	withinRadius = 200,
}: INearbyFacilities): Promise<NearbyFacility[]> {
	const { latitude, longitude } = coords;
	if (!latitude || !longitude) {
		return Promise.reject(`Invalid coordinates of latitude: ${latitude} and longitude: ${longitude}`);
	}
	const facilityBrand = brandThemeToFacilityBrand(brand);
	const facilitiesResponse = await getGraphQLClient(enterpriseGraphqlUrl).nearbyFacilities(
		{
			filter: {
				latitude,
				longitude,
				withinRadius,
				brand: facilityBrand as FacilityBrand,
			},
		},
		getGraphHeaders(appName, appVersion)
	);
	const nearbyFacilities = facilitiesResponse.nearbyFacilities as NearbyFacilitiesList;

	return nearbyFacilities.items;
}
interface IOnPageSchedulingDetails {
	coords: {
		latitude: number;
		longitude: number;
	};
	brand: TAGBrand;
}
async function getOnPageSchedulingDetails({ coords, brand }: IOnPageSchedulingDetails) {
	if (!coords.latitude || !coords.longitude) {
		return Promise.reject('Invalid coordinates');
	}

	if (brand === TAGBrand.WellNow || brand === TAGBrand.YouWellNow || brand === TAGBrand.LivWellNow) {
		const { schedulingSystem }: Location = await getLocationDetails({
			latitude: coords.latitude,
			longitude: coords.longitude,
		});
		if (schedulingSystem) {
			return Promise.resolve({ schedulingSystem });
		}
		return Promise.resolve({ schedulingSystem: 'Unknown' });
	}
	// For AspenDental and other brands, this remains to be implemented, for now, we are returning InHouse
	return Promise.resolve({ schedulingSystem: 'InHouse' });
}
const brandThemeToFacilityBrand = (brand: TAGBrand) => {
	//TODO: swap brands from FacilityBrand (EntGQL) to BffBrandType as they get migrated to fully use BEFFE instead of EntGQL
	switch (brand) {
		case TAGBrand.WellNow:
		case TAGBrand.YouWellNow:
		case TAGBrand.LivWellNow:
			return FacilityBrand.WellNow;
		case TAGBrand.ClearChoice:
		case TAGBrand.LobbyClearChoice:
		case TAGBrand.ConsultClearChoice:
			return FacilityBrand.ClearChoice;
		case TAGBrand.Motto:
		case TAGBrand.TeamTAG:
		case TAGBrand.AspenDental:
			return FacilityBrand.AspenDental;
		case TAGBrand.AZPetVet:
			return BffBrandType.AzPet;
		case TAGBrand.Lovet:
			return BffBrandType.Lovet;
		case TAGBrand.Chapter:
			return BffBrandType.Chapter;
		default:
			return FacilityBrand.AspenDental;
	}
};

export default function useFacilities(
	appName: string,
	appVersion: string,
	brand: TAGBrand,
	enterpriseGraphqlUrl: string,
	bffGraphqlUrl: string,
	useFacilityBeffeDataSource: boolean,
	user?: Maybe<IUser>,
	onPageScheduling?: boolean,
	facilityCode?: string,
	defaultToCdn?: boolean,
	transitionallyUseBeffe?: boolean,
	useSessionStorage?: boolean,
	shouldFetchNearbyFacility?: boolean
) {
	const {
		storageValue: storedFacilityCode,
		setValue,
		removeItem,
	} = useBrowserStorage({
		key: FACILITY_CODE_STORAGE_KEY,
		storage: useSessionStorage ? globalThis.sessionStorage : globalThis.localStorage,
	});

	const storageFacilityCode = storedFacilityCode?.toString();

	const [officeInfo, setOfficeInfo] = useState<FacilityFieldsFragment | null>(null);
	const [nearbyFacilities, setNearbyFacilities] = useState<BffFacility[] | undefined | null>(undefined);
	const [isWaitingForNearbyFacilities, setIsWaitingForNearbyFacilities] = useState(shouldFetchNearbyFacility);
	const [locationState, setLocationState] = useState<{
		nearByOfficeLocations: Array<NearbyFacility> | null;
		closestOfficeInfo: NearbyFacility | null;
		distance?: number | null;
		schedulingSystem?: string | null;
		user: IUser;
	}>({
		closestOfficeInfo: null,
		nearByOfficeLocations: null,
		distance: null,
		user: {
			hasIpBasedCoordinates: false,
			coordinates: {
				latitude: 0,
				longitude: 0,
			},
		},
	});
	const getNearbyFacilitiesByGeolocation = useCallback(
		async (officeInformation?: FacilityFieldsFragment) => {
			if (!shouldFetchNearbyFacility) {
				return;
			}

			const handleError = (error: Error | GeolocationPositionError) => {
				console.error('Error occurred: ', error);
				removeItem();
				setNearbyFacilities(null);
			};

			const handleSuccess = async (res: NearbyFacility[], nearbyFacilities: BffFacility[] | null) => {
				if (!res || res.length === 0 || res[0].facility === null) {
					removeItem();
					return false;
				}

				setLocationState((prev) => ({
					...prev,
					nearByOfficeLocations: res,
					closestOfficeInfo: res[0],
				}));
				setOfficeInfo(res[0].facility);
				setValue(res[0].facility.code);
				setNearbyFacilities(nearbyFacilities);

				const facility = res[0].facility;
				if (facility?.location?.latitude && facility?.location?.longitude && onPageScheduling) {
					try {
						const schedulingDetails = await getOnPageSchedulingDetails({
							coords: facility.location,
							brand,
						});
						if (schedulingDetails && schedulingDetails.schedulingSystem) {
							setLocationState((prev) => ({
								...prev,
								schedulingSystem: schedulingDetails.schedulingSystem,
							}));
						}
						return true;
					} catch (err) {
						console.error('Error getting scheduling system', err);
					}
				}
				return true;
			};

			const getPosition = (): Promise<GeolocationPosition> => {
				return new Promise((resolve, reject) => {
					globalThis.navigator?.geolocation.getCurrentPosition(resolve, reject);
				});
			};

			try {
				let position: { coords: { latitude: number; longitude: number } } | GeolocationPosition = {
					coords: { latitude: 0, longitude: 0 },
				};
				if (officeInformation?.location?.longitude && officeInformation?.location?.latitude) {
					position = {
						coords: {
							latitude: officeInformation?.location?.latitude as number,
							longitude: officeInformation?.location?.longitude as number,
						},
					};
				}

				if (defaultToCdn && !officeInformation) {
					position = {
						coords: {
							latitude: (user?.coordinates.latitude as number) || 0,
							longitude: (user?.coordinates.longitude as number) || 0,
						},
					};
				}

				if (!defaultToCdn && !officeInformation) {
					position = await getPosition();
				}

				let facilities: NearbyFacility[] = [];
				let nearbyFacilities: BffFacility[] | null = null;

				if (useFacilityBeffeDataSource) {
					const res = await getBffNearByFacilitiesByLocation(
						brandThemeToFacilityBrand(brand).toString() as BffBrandType,
						position?.coords.latitude || 0,
						position?.coords.longitude || 0,
						bffGraphqlUrl,
						appName,
						appVersion
					);
					facilities = mapFacilities(res?.facilityByLocation);
					nearbyFacilities =
						res?.facilityByLocation?.filter((facility): facility is BffFacility => facility !== null) ??
						null;
				} else {
					facilities = await getNearbyFacilities({
						appName,
						appVersion,
						enterpriseGraphqlUrl,
						brand,
						coords: position?.coords || { longitude: 0, latitude: 0 },
					});
				}

				setLocationState((prev) => ({
					...prev,
					user: {
						hasIpBasedCoordinates: defaultToCdn ? user?.hasIpBasedCoordinates : false,
						coordinates: position?.coords || { longitude: 0, latitude: 0 },
					},
				}));
				await handleSuccess(facilities, nearbyFacilities);
			} catch (error) {
				const geolocationError = error as GeolocationPositionError; // Type assertion

				if (
					(!defaultToCdn &&
						'code' in geolocationError &&
						geolocationError.code === geolocationError.PERMISSION_DENIED &&
						user?.hasIpBasedCoordinates) ||
					(user?.coordinates && user?.hasIpBasedCoordinates)
				) {
					console.info('Browser Geolocation is not enabled, using IP based coordinates', user.coordinates);
					try {
						let facilities: NearbyFacility[] = [];
						let nearbyFacilities: BffFacility[] | null = null;

						if (useFacilityBeffeDataSource) {
							const res = await getBffNearByFacilitiesByLocation(
								brandThemeToFacilityBrand(brand).toString() as BffBrandType,
								user?.coordinates.latitude || 0,
								user?.coordinates.longitude || 0,
								bffGraphqlUrl,
								appName,
								appVersion
							);
							facilities = mapFacilities(res?.facilityByLocation);
							nearbyFacilities =
								res?.facilityByLocation?.filter(
									(facility): facility is BffFacility => facility !== null
								) ?? null;
						} else {
							facilities = await getNearbyFacilities({
								appName,
								appVersion,
								enterpriseGraphqlUrl,
								brand,
								coords: user?.coordinates || { longitude: 0, latitude: 0 },
							});
						}

						setLocationState((prev) => ({
							...prev,
							user: {
								hasIpBasedCoordinates: true,
								coordinates: user?.coordinates || { longitude: 0, latitude: 0 },
							},
						}));
						await handleSuccess(facilities, nearbyFacilities);
					} catch (err) {
						const error = err as Error;
						handleError(error);
					}
				} else {
					handleError(error as Error);
				}
			} finally {
				setIsWaitingForNearbyFacilities(false);
			}
		},
		[
			appName,
			appVersion,
			enterpriseGraphqlUrl,
			bffGraphqlUrl,
			useFacilityBeffeDataSource,
			brand,
			setValue,
			removeItem,
			user,
			onPageScheduling,
			defaultToCdn,
			shouldFetchNearbyFacility,
		]
	);

	const router = useRouter();
	const facilityCodeFromURL = router.isReady
		? new URL(`${globalThis?.location?.origin || 'http://localhost'}${router.asPath}`).searchParams.get('fc')
		: null;
	useEffect(() => {
		// 1. If facility code is passed in the URL, use that to fetch the facility details
		// 2. If 1. failed, and facility code passed from server side props, use that to fetch the facility details
		// 3. if 1. and 2. both failed, and facility code is stored in local storage, use that to fetch the facility details
		// Final fallback: use the geolocation to fetch the facility details
		// console.log('in use facilities')

		if (facilityCodeFromURL || facilityCode || storageFacilityCode) {
			const tempFacilityCode = facilityCodeFromURL ?? facilityCode ?? storageFacilityCode ?? '';
			setIsWaitingForNearbyFacilities(false);

			if (useFacilityBeffeDataSource || transitionallyUseBeffe) {
				void getBffFacilityById(
					brandThemeToFacilityBrand(brand).toString() as BffBrandType,
					tempFacilityCode,
					bffGraphqlUrl,
					appName,
					appVersion
				).then((data) => {
					const facilities = mapFacilities(data?.facilityById ? [data.facilityById] : null);
					const newOfficeInfo = facilities[0];
					if (!newOfficeInfo) {
						return false;
					}

					setValue(tempFacilityCode);
					setOfficeInfo(newOfficeInfo.facility);
					return true;
				});
			} else {
				const promiseMap = [
					getFacilityDetails(brand, tempFacilityCode, appName, appVersion, enterpriseGraphqlUrl),
				];
				void Promise.allSettled(promiseMap).then(([newOfficeInfo]) => {
					const facilityDetails = (newOfficeInfo as PromiseFulfilledResult<FacilityFieldsFragment>).value;
					if (!facilityDetails) {
						return false;
					}

					setValue(tempFacilityCode);
					setOfficeInfo(facilityDetails);
					return true;
				});
			}
		} else {
			if (shouldFetchNearbyFacility) {
				void getNearbyFacilitiesByGeolocation();
			}
		}
	}, [
		brand,
		appName,
		appVersion,
		getNearbyFacilitiesByGeolocation,
		storageFacilityCode,
		facilityCodeFromURL,
		setValue,
		enterpriseGraphqlUrl,
		removeItem,
		facilityCode,
		useFacilityBeffeDataSource,
		bffGraphqlUrl,
		transitionallyUseBeffe,
		shouldFetchNearbyFacility,
		setIsWaitingForNearbyFacilities,
	]);

	useEffect(() => {
		if (officeInfo) {
			if (locationState.nearByOfficeLocations) {
				const isOfficeNearby = locationState.nearByOfficeLocations.some(
					(office) => office.facility.code === officeInfo.code
				);
				if (isOfficeNearby) {
					//TODO: update the distance
				}
				setIsWaitingForNearbyFacilities(false);
			} else {
				void getNearbyFacilitiesByGeolocation(officeInfo);
			}
		}
	}, [
		officeInfo,
		locationState,
		getNearbyFacilitiesByGeolocation,
		appName,
		appVersion,
		setIsWaitingForNearbyFacilities,
	]);

	return {
		officeInfo,
		facilityCode: storageFacilityCode,
		locationState,
		facilityBrand: brandThemeToFacilityBrand(brand),
		nearbyFacilities,
		isWaitingForNearbyFacilities,
	};
}
